import React from 'react';
import Layout from '../components/layout';
import Video from '../components/video';
import Stats from '../components/stats';
import Qualities from '../components/qualities';
import Products from '../components/products';
import SEO from '../components/seo';
import PageLink from '../components/page-link';
import A160 from '../animations/a160';
import A80 from '../animations/a80';
import A400 from '../animations/a400';
import Svg from '../components/svg';
import Drop from '../animations/drop';
import QualitiesSmall from '../components/qualities-small';
import StatsSmall from '../components/stats-small';
import ProductSwiper from '../components/product-swiper';
import NewsSwiper from '../components/news-swiper';
import MilkLink from '../components/milk-link';

export default ({ pageContext: { content, pages, lang, texts, langs } }) => {
  const data = content.data;
  let meet;
  if (data.meet_enabled) {
    meet = (
      <div className='meet' data-sal='fadein' data-sal-duration='1000'>
        <A400 className='liquid-5' />
        <div
          data-sal='fadein'
          data-sal-duration='1000'
          className='layer-label'
          dangerouslySetInnerHTML={{ __html: data.meet_section }}
        />
        <div className='mid-wrap'>
          <div className='meet-block'>
            <div className='meet-left'>
              <div data-sal='slide-up' data-sal-duration='1000'>
                <div dangerouslySetInnerHTML={{ __html: data.meet_date }} />
                <h3 dangerouslySetInnerHTML={{ __html: data.meet_text }} />
                <a className='btn' href={data.meet_btn_link}>
                  {data.meet_btn}
                </a>
              </div>
            </div>
            <div
              data-sal='slide-up'
              data-sal-duration='1000'
              className='meet-right'
            >
              <img
                src={data.meet_image}
                alt={data.meet_text}
                title={data.meet_text}
              />
            </div>
            <A80
              className='svg-2'
              opacity='1'
              style1={{ stopColor: '#e8efff', stopOpacity: '0' }}
              style2={{ stopColor: '#e8efff' }}
            />
            <A400
              className='svg-1'
              opacity='1'
              style1={{ stopColor: '#E8EFFF', stopOpacity: '0' }}
              style2={{ stopColor: '#E8EFFF' }}
            />
          </div>
        </div>
      </div>
    );
  }

  // <PageLink className="btn" lang={lang} pages={pages} to="company">{data.hero_btn}</PageLink>

  return (
    <Layout pages={pages} lang={lang} texts={texts} langs={langs}>
      <SEO
        description={data.seo_description}
        title={data.seo_title}
        image={data.seo_image}
        name='index'
      />
      <A400 className='liquid-hero' />
      <div className='hero'>
        <div
          className='wrap-full pl-xs flex'
          data-sal='slide-right'
          data-sal-duration='1000'
        >
          <div className='hero__left' data-sal='up' data-sal-duration='1000'>
            <h1 dangerouslySetInnerHTML={{ __html: data.hero_title }} />
            <a className='btn' href={data.hero_btn_url}>
              {data.hero_btn}
            </a>
          </div>
        </div>
        <div className='hero__image'>
          <img
            data-sal='slide-left'
            data-sal-duration='1000'
            src={data.hero_image}
            alt={data.hero_title}
            title={data.hero_title}
          />
        </div>
      </div>
      {/* <Video videoBtn={data.video_btn} videoImage={data.video_image} youtubeId={data.youtube_id} /> */}
      <A160 className='liquid-1' />
      {/* <A80 className='liquid-2' /> */}
      <div className='layer'>
        <A400 className='liquid-2' />
        <div className='home-about wrap-full pl-xxl pr-l flex'>
          {/* <div
            data-sal='fade'
            data-sal-duration='1000'
            className='layer-label'
            dangerouslySetInnerHTML={{ __html: data.about_section }}
          /> */}
          <div className='left'>
            <h2
              data-sal='slide-right'
              data-sal-duration='1000'
              dangerouslySetInnerHTML={{ __html: data.about_title }}
            />
            {data.about_subtitle && data.about_subtitle !== null && (
              <div
                className='home-about__sub'
                data-sal='slide-right'
                data-sal-duration='1000'
              >
                <h3 dangerouslySetInnerHTML={{ __html: data.about_subtitle }} />
                <MilkLink
                  to={data.about_btn_url}
                  className={'border-link cnv-link'}
                  external={true}
                >
                  {data.about_btn}
                  <Drop />
                </MilkLink>
              </div>
            )}
          </div>
          <div className='right' data-sal='slide-left' data-sal-duration='1000'>
            <div
              className='text'
              dangerouslySetInnerHTML={{ __html: data.about_text }}
            />
            <QualitiesSmall data={texts} />
          </div>
          {/* <img
                  data-sal='slide-right'
                  data-sal-duration='1000'
                  className='desktop-hide'
                  src={data.about_image}
                  alt={data.about_title}
                  title={data.about_title}
                /> */}
          {/* <div
                  data-sal='slide-right'
                  data-sal-duration='1000'
                  dangerouslySetInnerHTML={{ __html: data.about_text }}
                /> */}
          {/* <br /> */}
          {/* <PageLink
                  data-sal='slide-right'
                  data-sal-duration='1000'
                  className='btn'
                  lang={lang}
                  pages={pages}
                  to='company'
                >
                  {data.about_btn}
                </PageLink> */}
          {/* <div className='img'>
                <img
                  data-sal='slide-left'
                  data-sal-duration='1000'
                  src={data.about_image}
                  alt={data.about_title}
                  title={data.about_title}
                />
                <A160 className='liquid-3' />
              </div> */}
        </div>
      </div>
      <StatsSmall data={texts} />
      {/* {meet} */}
      <div className='wrap-full pl-xl pr-xl layer-slider-top'>
        <A80 className='liquid-3' />
        <h2
          className='h2'
          data-sal='slide-right'
          data-sal-duration='1000'
          dangerouslySetInnerHTML={{ __html: data.products_slider_title }}
        />
      </div>
      <ProductSwiper pages={pages} lang={lang} texts={texts} />

      <div className='layer layer-important'>
        <A400 className='liquid-4' />
        <A80 className='liquid-6' />
        <A80 className='liquid-7' />
        <div className='small-wrap'>
          <h2
            data-sal='slide-left'
            data-sal-duration='1000'
            dangerouslySetInnerHTML={{ __html: data.services_title }}
          />
          <a className='btn' href={data.services_link}>
            {data.services_btn}
          </a>
        </div>
        <div className='important-bottom'>
          <div className='wrap-full'>
            <div>
              <div data-sal='slide-left' data-sal-duration='800' className='no'>
                1<Svg name='circle' />
              </div>
              <div
                data-sal-delay='200'
                data-sal='slide-up'
                data-sal-duration='800'
              >
                <h3 dangerouslySetInnerHTML={{ __html: data.services_1 }} />
              </div>
            </div>
            <div>
              <div
                data-sal-delay='400'
                data-sal='slide-left'
                data-sal-duration='800'
                className='no'
              >
                2<Svg name='circle' />
              </div>
              <div
                data-sal-delay='400'
                data-sal='slide-up'
                data-sal-duration='800'
              >
                <h3 dangerouslySetInnerHTML={{ __html: data.services_2 }} />
              </div>
            </div>
            <div>
              <div
                data-sal-delay='600'
                data-sal='slide-left'
                data-sal-duration='800'
                className='no'
              >
                3<Svg name='circle' />
              </div>
              <div
                data-sal-delay='600'
                data-sal='slide-up'
                data-sal-duration='800'
              >
                <h3 dangerouslySetInnerHTML={{ __html: data.services_3 }} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <img
        data-sal='fade'
        data-sal-duration='1000'
        className='mobile-hide'
        src={data.products_image_desktop}
        alt=''
        title=''
      />
      <img
        data-sal='fade'
        data-sal-duration='1000'
        className='desktop-hide'
        src={data.products_image_mobile}
        alt=''
        title=''
      /> */}
      {/* <Qualities data={texts} from='home' /> */}
      <div className='wrap-full pl-l pr-xl layer-slider-top'>
        <h2
          className='h2'
          data-sal='slide-right'
          data-sal-duration='1000'
          dangerouslySetInnerHTML={{ __html: data.news_slider_title }}
        />
      </div>
      <NewsSwiper pages={pages} lang={lang} texts={texts} />
    </Layout>
  );
};
