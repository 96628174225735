import React from 'react';
import A160 from '../animations/a160';
import A400 from '../animations/a400';

const QualitiesSmall = ({ data }) => (
  <div className='qualities-small'>
    {data.quality_block_1_img && (
      <div>
        {data.quality_block_1_url ? (
          <a target='_blank' href={data.quality_block_1_url} download>
            <img
              src={data.quality_block_1_img}
              alt={data.quality_block_1_title}
              title={data.quality_block_1_title}
            />
          </a>
        ) : (
          <img
            src={data.quality_block_1_img}
            alt={data.quality_block_1_title}
            title={data.quality_block_1_title}
          />
        )}
      </div>
    )}
    {data.quality_block_2_img && (
      <div>
        {data.quality_block_2_url ? (
          <a target='_blank' href={data.quality_block_2_url}>
            <img
              src={data.quality_block_2_img}
              alt={data.quality_block_2_title}
              title={data.quality_block_2_title}
            />
          </a>
        ) : (
          <img
            src={data.quality_block_2_img}
            alt={data.quality_block_2_title}
            title={data.quality_block_2_title}
          />
        )}
      </div>
    )}
    {data.quality_block_3_img && (
      <div>
        {data.quality_block_3_url ? (
          <a target='_blank' href={data.quality_block_3_url}>
            <img
              src={data.quality_block_3_img}
              alt={data.quality_block_3_title}
              title={data.quality_block_3_title}
            />
          </a>
        ) : (
          <img
            src={data.quality_block_3_img}
            alt={data.quality_block_3_title}
            title={data.quality_block_3_title}
          />
        )}
      </div>
    )}
    {data.quality_block_4_img && (
      <div>
        {data.quality_block_4_url ? (
          <a target='_blank' href={data.quality_block_4_url}>
            <img
              src={data.quality_block_4_img}
              alt={data.quality_block_4_title}
              title={data.quality_block_4_title}
            />
          </a>
        ) : (
          <img
            src={data.quality_block_4_img}
            alt={data.quality_block_4_title}
            title={data.quality_block_4_title}
          />
        )}
      </div>
    )}
    {data.quality_block_5_img && (
      <div>
        {data.quality_block_5_url ? (
          <a target='_blank' href={data.quality_block_5_url}>
            <img
              src={data.quality_block_5_img}
              alt={data.quality_block_5_title}
              title={data.quality_block_5_title}
            />
          </a>
        ) : (
          <img
            src={data.quality_block_5_img}
            alt={data.quality_block_5_title}
            title={data.quality_block_5_title}
          />
        )}
      </div>
    )}
    {data.quality_block_6_img && (
      <div>
        {data.quality_block_6_url ? (
          <a target='_blank' href={data.quality_block_6_url}>
            <img
              src={data.quality_block_6_img}
              alt={data.quality_block_6_title}
              title={data.quality_block_6_title}
            />
          </a>
        ) : (
          <img
            src={data.quality_block_6_img}
            alt={data.quality_block_6_title}
            title={data.quality_block_6_title}
          />
        )}
      </div>
    )}
  </div>
);

export default QualitiesSmall;
