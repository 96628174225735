import React from 'react';
import 'swiper/css';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import MilkLink from './milk-link';
import Svg from './svg';
import Drop from '../animations/drop';
import useMediaQuery from './use-media-query';

function NewsSwiper({ pages, lang, texts }) {
  const news = pages.filter((page) => page[lang].template === 'news')[0][lang];
  const newsItems = news.items;
  const isMobile = useMediaQuery(768);
  return (
    <div className='wrap-full news-swiper'>
      <Splide
        hasTrack={false}
        aria-label='news'
        options={{
          rewind: false,
          pagination: false,
          gap: '3em',
          perPage: isMobile ? 1 : 3,
          perMove: 1,
          padding: {
            right: isMobile ? 0 : '1.5rem',
          },
        }}
      >
        <div className='splide__arrows'>
          <button className='splide__arrow splide__arrow--prev'>
            <Svg name='arrow-right' />
          </button>
          <button className='splide__arrow splide__arrow--next'>
            <Svg name='arrow-right' />
          </button>
        </div>
        <SplideTrack>
          {newsItems.map((item) => (
            <SplideSlide key={item.url}>
              <div 
                className='news-slide'
                data-sal='slide-left'
                data-sal-duration="1000"
              >
                <div className='img-wrap'>
                  <img src={item.image_home} alt={item.title} title={item.title} />
                  <MilkLink to={`/${lang}${item.url}`}></MilkLink>
                </div>
                <div className='date'>{item.date}</div>
                <div
                  className='h3--secondary'
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <MilkLink
                  className='more border-link cnv-link'
                  to={`/${lang}${item.url}`}
                >
                  {news.data.read_more}
                  <Drop />
                </MilkLink>
              </div>
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
    </div>
  );
}

export default NewsSwiper;
